/**
 * Module dependencies.
 */

import { Breakpoint } from 'src/styles/breakpoints';
import { useBreakpoint } from './use-breakpoint';

/**
 * Export hook `useDeviceSource`.
 */

export function useDeviceSource<T>(sources?: [T?, T?], breakpoint: Breakpoint = 'ms') {
  const isMobile = useBreakpoint(0, breakpoint);

  if (!sources) {
    return;
  }

  const [desktopSource, mobileSource] = sources;

  return isMobile ? (mobileSource ?? desktopSource) : (desktopSource ?? mobileSource);
}
