/**
 * Module dependencies.
 */

import { enableActivityTracking, newTracker, trackPageView } from '@snowplow/browser-tracker';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSnowplowTracking } from 'src/hooks/use-snowplow-tracking';

/**
 * Constants.
 */

const appId = process.env.NEXT_PUBLIC_SNOWPLOW_APP_ID;
const collectorUrl = process.env.NEXT_PUBLIC_SNOWPLOW_COLLECTOR_URL;

if (appId && collectorUrl) {
  newTracker('sp1', collectorUrl, {
    appId,
    contexts: {
      browser: true,
      session: true,
      webPage: true
    }
  });
}

const handleRouteChange = () => {
  if (!appId || !collectorUrl) {
    return;
  }

  trackPageView();

  enableActivityTracking({
    heartbeatDelay: 10,
    minimumVisitLength: 30
  });
};

/**
 * Export `Snowplow` component.
 */

export function Snowplow() {
  const router = useRouter();

  useEffect(() => {
    handleRouteChange();
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useSnowplowTracking();

  return null;
}
