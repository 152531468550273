/**
 * Module dependencies.
 */

import { regexes } from 'src/core/constants/regexes';
import acceptLanguageParser from 'accept-language-parser';

/**
 * `GetLanguageData` type.
 */

export type GetLanguageData = {
  acceptLanguages: acceptLanguageParser.Language[];
  region: Region;
  requestedLanguage: string | undefined;
};

/**
 * `GetLanguageConfig` type.
 */

export type GetLanguageConfig = {
  defaultLanguage: string;
  regionLanguages: Record<Region, string[]>;
};

/**
 * `GetRegionData` type.
 */

export type GetRegionData = {
  acceptLanguages: acceptLanguageParser.Language[];
  clientRegion: Region | undefined;
  requestedRegion: Region | undefined;
};

/**
 * `GetRegionConfig` type.
 */

export type GetRegionConfig = {
  defaultRegion: Region;
  forcedRegions: Region[];
  regions: Region[];
};

/**
 * Export `isLocale` util.
 */

export const isLocale = (string: string) => regexes.locale.test(string);

/**
 * Export `getSlugLocale` util.
 */

export function getSlugLocale(slug: string[]) {
  if (slug.length === 0 || !isLocale(slug[0])) {
    return {
      languageCode: process.env.DEFAULT_LANGUAGE,
      region: process.env.DEFAULT_REGION as Region
    };
  }

  const [languageCode, region] = slug[0].split('-');

  return {
    languageCode,
    region: (region ?? process.env.DEFAULT_REGION) as Region
  };
}

/**
 * Export `getCtxLocale` util.
 */

export function getCtxLocale(slug?: string | string[] | null) {
  try {
    if (!Array.isArray(slug)) {
      throw new Error('Slug must be an array.');
    }

    return getSlugLocale(slug);
  } catch {
    return {
      languageCode: process.env.DEFAULT_LANGUAGE,
      region: process.env.DEFAULT_REGION
    };
  }
}

/**
 * Export `getLocale` util.
 */

export function getLocale(region: Region, language: string) {
  if (region === process.env.DEFAULT_REGION && language === process.env.DEFAULT_LANGUAGE) {
    return '';
  }

  if (!region || region === process.env.DEFAULT_REGION) {
    return language;
  }

  return `${language}-${region}`;
}

/**
 * `getLanguage` function.
 * The language is decided in the following order:
 * 1. If requested language is available in region then requested language.
 * 2. If prefered language is available in region then prefered language.
 * 3. If default language is available in region then default language.
 * 4. First language in region.
 */

export const getLanguage = (
  { acceptLanguages, region, requestedLanguage }: GetLanguageData,
  { defaultLanguage, regionLanguages }: GetLanguageConfig
) => {
  if (requestedLanguage && regionLanguages[region]?.includes(requestedLanguage)) {
    return requestedLanguage;
  }

  const preferedLanguage = acceptLanguages.find(language => regionLanguages[region]?.includes(language.code))?.code;

  return (
    preferedLanguage ?? regionLanguages[region]?.find(lang => lang === defaultLanguage) ?? regionLanguages[region]?.[0]
  );
};

/**
 * `getRegion` function.
 * The region is decided in the following order:
 * 1. If client region is forced then client region.
 * 2. If requested region is available then requested region.
 * 3. If client region is available then client region.
 * 5. If regions in accept languages are available then the first region in accept languages (sorted by priority).
 * 4. Default region.
 */

export const getRegion = (
  { acceptLanguages, clientRegion, requestedRegion }: GetRegionData,
  { defaultRegion, forcedRegions, regions }: GetRegionConfig
): Region => {
  if (clientRegion && forcedRegions.includes(clientRegion as Region)) {
    return clientRegion;
  }

  if (requestedRegion && regions.includes(requestedRegion)) {
    return requestedRegion;
  }

  if (clientRegion && regions.includes(clientRegion as Region)) {
    return clientRegion;
  }

  return (
    (acceptLanguages.find(language => regions.includes(language.region as Region))?.region as Region) ?? defaultRegion
  );
};

/**
 * Export `getLocalizedHref` function.
 */

export const getLocalizedHref = (href: string, locale?: string) => {
  if (locale && href.startsWith('/') && !isLocale(href.split('/')[1])) {
    return `/${locale}${href}`;
  }

  return href;
};
