/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import { FooterFragment } from 'src/api/entities/footer/types';
import { Link, linkAnimatedStyles } from 'src/components/core/links';
import { LocaleSelect } from './locale-select';
import { RawHtml } from 'src/components/core/raw-html';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { ifProp } from 'styled-tools';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useSettings } from 'src/context/settings';
import ReactQRCode from 'react-qr-code';
import i18nConfig from 'i18n.config';
import logoSvg from 'src/assets/svgs/logo.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = ComponentPropsWithoutRef<'footer'> & {
  pageFooter?: FooterFragment;
};

/**
 * `Divider` styled component.
 */

const Divider = styled.hr`
  background-color: var(--color-neutral90);
  border: none;
  height: 1px;
  margin: 40px 0;
`;

/**
 * `VerticalDivider` styled component.
 */

const VerticalDivider = styled.div`
  background-color: var(--color-neutral90);
  margin: 0 40px;
  width: 1px;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.footer`
  background-color: var(--color-neutral105);
  padding: 48px 8px 72px;

  ${media.min.md`
    padding: 120px 72px;
  `}
`;

/**
 * `TopSection` styled component.
 */

const TopSection = styled.div`
  ${media.min.md`
    display: grid;
    grid-gap: 48px;
    grid-template-columns: 2fr 3fr;
  `}
`;

/**
 * `MiddleSection` styled component.
 */

const MiddleSection = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.min.md`
    align-items: stretch;
    justify-content: start;
  `}
`;

/**
 * `QRWrapper` styled component.
 */

const QRWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 200px;
`;

/**
 * `Links` styled component.
 */

const Links = styled.div`
  column-count: 1;

  ${media.min.ms`
    column-count: 2;
    column-gap: 24px;
  `}

  ${media.min.lg`
    column-count: 4;
    column-gap: 48px;
  `}
`;

/**
 * `LinkGroup` styled component.
 */

const LinkGroup = styled.div<{ isEmpty?: boolean }>`
  ${ifProp('isEmpty', 'break-after: avoid;')}

  break-inside: avoid-column;
  margin-bottom: 40px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 16px;
  }
`;

/**
 * `Socials` styled component.
 */

const Socials = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

/**
 * `FinePrint` styled component.
 */

const FinePrint = styled(Text).attrs({ color: 'neutral40', variant: 'small' })`
  white-space: pre-line;

  a {
    ${linkAnimatedStyles}

    color: var(--color-primary);
  }
`;

/**
 * Export `Footer` component.
 */

export const Footer = ({ pageFooter, ...props }: Props) => {
  const { globalSettings, locale, regionSettings } = useSettings();
  const { footer: settingsFooter } = regionSettings ?? {};
  const footer = pageFooter ?? settingsFooter;
  const { socialNetworks } = globalSettings ?? {};
  const isDesktop = useBreakpoint('md');
  const isForced = useMemo(() => (i18nConfig?.forcedRegions as string[]).includes(locale.regionCode), [locale]);

  if (!footer) {
    return null;
  }

  return (
    <Wrapper {...props} data-theme={'dark'}>
      <Container>
        <TopSection>
          {isDesktop && (
            <QRWrapper>
              <Text color={'neutral0'} fontWeight={400} variant={'paragraph1'}>
                {footer?.qrCodeMessage}
              </Text>

              {footer?.qrCodeUrl && (
                <ReactQRCode
                  bgColor={'transparent'}
                  fgColor={'var(--color-primary)'}
                  level={'H'}
                  size={96}
                  value={footer?.qrCodeUrl}
                />
              )}
            </QRWrapper>
          )}

          <Links>
            {footer?.groupLinks?.map((group, index) => (
              <LinkGroup isEmpty={!group.links?.length} key={index}>
                <Text color={'primaryForDark'} fontWeight={700} variant={'paragraph2'}>
                  {'cta' in group ? (
                    <Link {...omit(group.cta, 'label')} data-underline-animated={isDesktop}>
                      {group.cta.label}
                    </Link>
                  ) : (
                    group.label
                  )}
                </Text>

                {(group.links?.length ?? 0) > 0 && (
                  <Text as={'div'} color={'neutral0'} variant={'paragraph2'}>
                    {group.links?.map(
                      (cta, ctaIndex) =>
                        cta?.label && (
                          <div key={cta.label + index + ctaIndex}>
                            <Link {...omit(cta, 'label')} data-underline-animated={isDesktop}>
                              {cta.label}
                            </Link>
                          </div>
                        )
                    )}
                  </Text>
                )}
              </LinkGroup>
            ))}
          </Links>
        </TopSection>

        <Divider />

        <MiddleSection>
          <Svg icon={logoSvg} size={'128px'} />

          {isDesktop && <VerticalDivider />}

          <Socials>
            {socialNetworks?.map(
              ({ icon, label, showInFooter, url }) =>
                url &&
                showInFooter &&
                icon && (
                  <Link href={url} key={label} title={label}>
                    <Svg color={'var(--color-primary)'} icon={icon} size={'32px'} />
                  </Link>
                )
            )}
          </Socials>

          {!isForced && footer.localeSelect && isDesktop && <LocaleSelect />}
        </MiddleSection>

        <Divider />

        {!isForced && footer.localeSelect && !isDesktop && <LocaleSelect />}

        <RawHtml element={FinePrint}>{footer?.finePrint}</RawHtml>
      </Container>
    </Wrapper>
  );
};
