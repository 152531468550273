/**
 * Module dependencies.
 */

import { BaseButtonProps, BaseWrapper, useIcon } from './common';
import { Svg } from 'src/components/core/svg';
import { buttonThemes } from './card-button-theme-config';
import { forwardRef } from 'react';
import { media } from 'src/styles/media';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = BaseButtonProps & {
  size?: 'large' | 'small';
  variant?: 'primary' | 'neutral';
};

/**
 * `buttonSizes` constant.
 */

const buttonSizes = css`
  --button-font-size: 16px;
  --button-icon-size: 16px;
  --button-line-height: 1.2;
  --button-padding-h: 12px;
  --button-padding-v: 4px;

  ${media.min.md`
    --button-padding-h: 12px;
    --button-padding-v: 8px;

    &[data-size='large'] {
      --button-font-size: 20px;
      --button-icon-size: 24px;
      --button-line-height: 1.3;
    }
  `}
`;

/**
 * `Wrapper` styled component.
 */

export const Wrapper = styled(BaseWrapper).withConfig({
  shouldForwardProp: prop => !['hasLinkIcon'].includes(prop)
})`
  ${buttonSizes}
  ${buttonThemes}

  backdrop-filter: blur(7px);
  display: grid;
  grid-gap: 4px;
  max-width: 100%;

  &[data-stretch='true'] {
    grid-template-columns: repeat(2, auto);
    justify-items: center;
    width: 100%;
  }

  &:not([data-stretch='true']) {
    grid-template-columns: 1fr;

    &[data-has-icon='true'] {
      grid-template-columns: 1fr max-content;
    }
  }
`;

/**
 * `ChildrenWrapper` styled component.
 */

const ChildrenWrapper = styled.span`
  margin: 0 4px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Export `CardButton` component.
 */

export const CardButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const { children, iconStyle, size = 'large', stretch, variant = 'neutral', ...rest } = props;
  const icon = useIcon(props);

  return (
    <Wrapper
      data-card-button
      data-has-icon={!!icon}
      data-size={size}
      data-stretch={stretch}
      data-variant={variant}
      ref={ref}
      {...rest}
      style={{
        ...rest.style
      }}
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>

      {icon && <Svg icon={icon} size={'var(--button-icon-size)'} style={iconStyle} />}
    </Wrapper>
  );
});

/**
 * `CardButton` display name.
 */

CardButton.displayName = 'CardButton';
